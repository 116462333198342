import Konva from "konva";
import { uid } from "uid";
import { deselectGroup, destroyTransformer, setId } from "../utils";
import { AppStage } from "@/core/AppStage";
import { mainGroups } from "@/core/MainGroups";

let builder;
let borderLayer = {};
let clonePartition = {};
let transformStartBound;

//partition generation
export const generatePartition = (configs, component, partitionId, position) => {
  const appStage = new AppStage();
  builder = component;
  const id = partitionId ? partitionId : setId();
  const partitionWidth = configs && configs.width ? configs.width : 10;
  const partitionHeight = configs && configs.height ? configs.height : 100;
  const draggable = configs.draggable !== undefined ? configs.draggable : true;
  //create stage group component
  let mainGroup = new Konva.Group({
    id,
    configs,
    draggable: draggable,
    rotation: 0,
    width: partitionWidth,
    height: partitionHeight,
    type: 'partition',
    category: 'decor', 
    transforming: true
  });

  //create layer for transforming
  let transformer = new Konva.Rect({
    width: partitionWidth,
    height: partitionHeight,
    type: "transform",
  })

  //create stage inner components - arc, top line, text and shape

  let layerBorder = new Konva.Rect({
    width: partitionWidth + 20,
    height: partitionHeight + 20,
    x: -10,
    y: -10,
    strokeWidth: 1,
    dash: [5,5],
    type: "border",
  });

  let partition = new Konva.Rect({
    width: partitionWidth,
    height: partitionHeight,
    fill: 'gray',

  })

  //add stage components in group component
  mainGroup.add(partition);
  mainGroup.add(layerBorder);
  mainGroup.add(transformer);
  //set new values for variables
  mainGroups.add(mainGroup, id)
  // limitTrBoundBox(transformer);
  transformer.on("transformstart", () => {
    const konvaElement = mainGroups.get(id);
    const rotation = konvaElement.attrs.rotation;
    konvaElement.rotation(0);
    transformStartBound = transformer.getClientRect();
    konvaElement.rotation(rotation);
  })

  transformer.on("transform", () => {
    resizepartition(mainGroups.get(id), transformer, partition, layerBorder, appStage.scaleX(), transformStartBound);
  })

  transformer.on("transformend", () => {
    const konvaElement = mainGroups.get(id);
    recountSize(konvaElement, transformer, partition, layerBorder, appStage.scaleX(), appStage, configs)
  })

  borderLayer[id] = layerBorder;
  borderLayer[id].moveToTop();
 
  if (position) {
    mainGroup.setAttrs({
      x: position.x,
      y: position.y,
      offsetX: partitionWidth / 2,
      offsetY: partitionHeight / 2,
    })

  } else {
    mainGroup.setAttrs({
      offsetX: partitionWidth / 2,
      offsetY: partitionHeight / 2,
      x: ((-appStage.x() + appStage.width() / 2) / appStage.scaleX()),
      y: (((-appStage.y() + 40) / appStage.scaleY() + (partitionHeight / 2))),
    });
  }

  const currentGroup = mainGroups.get(id);

  if(configs && configs.rotation) {
    rotatePartition(id, configs.rotation, configs)
  }
  
  if(appStage.editing && currentGroup.attrs.draggable) {
    currentGroup.setAttrs({ 
      draggable: false,
      editDrag: true
    });
  }

  return { mainGroup, idPartition: id, borderLayer };
};

//partition edit cancel
export const cancelPartitionChanges = (configs) => {
  const appStage = new AppStage();
  const mainLayer = appStage.children[0];
  const { layerId } = configs;
  const layerElement = mainGroups.get(layerId);
  const position = layerElement.position();

  layerElement.destroy();

  const { mainGroup } = generatePartition(
    configs,
    builder,
    layerId,
    // attrs,
    position
  );
  mainLayer.add(mainGroup);
  deselectGroup();
};

//rotate partition
export const rotatePartition = (id, rotation, configs) => {
  // const appStage = new AppStage();
  const partition = mainGroups.get(id);
  partition.rotation(rotation);
  const normalizedConfigs = { ...partition.attrs.configs, rotation };
  partition.setAttrs({ configs: normalizedConfigs });

};

//update partition
export const updatePartitionStage = (id, payload) => {
  const partition = mainGroups.get(id);
  const rotation = partition.rotation();
  if (rotation){
    partition.rotation(rotation);
  }
};

export const partitionActions = {
  editAction: (id, component) => {
    const currentGroup = mainGroups.get(id);
    const appStage = new AppStage();
    clonePartition[id] = _.cloneDeep(currentGroup);
    AppStage.addKey('editing', id);

    const confs = currentGroup.attrs.configs;

    component.editPartition(id, confs, currentGroup);

    if(appStage.transformer) {
      destroyTransformer();
    }

    borderLayer[id].setAttrs({stroke: ''});
  },
  copyAction: (id, component, configs) => {
    const currentGroup = mainGroups.get(id);
    const appStage = new AppStage();
    const mainLayer = appStage.children[0];

    const conf = currentGroup.attrs.configs;
    const updatedConf = {...conf, draggable: true};
    const { mainGroup } = generatePartition(updatedConf, component);
    mainLayer.add(mainGroup);
    deselectGroup();
    borderLayer[id].setAttrs({stroke: ''});
  }
}

//resize and reposition partition elements during transform
const resizepartition = (group, transformer, partition, border, scale, start) => {
  const rotation = group.attrs.rotation;

  if(rotation !== 0) {
    group.rotation(0);
    // name.rotation(0);
  }

  const newParams = transformer.getClientRect();
  const newWidth = newParams.width / scale;
  const newHeight = newParams.height / scale;
  const shiftX = (newParams.x - start.x) /scale;
  const shiftY = (newParams.y - start.y) /scale;

  partition.setAttrs({
    width: newWidth,
    height: newHeight,
    offsetX: -shiftX,
    offsetY: -shiftY,
  })

  border.setAttrs({
    width: newWidth + 20,
    height: newHeight + 20,
    offsetX: -shiftX,
    offsetY: -shiftY,
  })

  if(rotation) {
    group.rotation(rotation);
  }
}

//resize and reposition partition and partition elements after transform
const recountSize = (group, transformer, partition, border, scale) => {
  const appStage = new AppStage();
  let newParams = transformer.getClientRect();

  const currentWidth = newParams.width/scale;
  const currentHeight = newParams.height/scale;
  const currentX = newParams.x/scale;
  const currentY = newParams.y/scale;
  let finalWidth = currentWidth;
  let finalHeight = currentHeight;
  const rotation = group.attrs.rotation;

  if(rotation !== 0) {
    group.rotation(0);
    newParams = transformer.getClientRect();
    finalWidth = newParams.width/scale;
    finalHeight =  newParams.height/scale;
  }

  const resetedPosition = {
    x: 0,
    y: 0
  }

  group.setAttrs({
    width: finalWidth,
    height: finalHeight,
    offsetX: finalWidth / 2,
    offsetY: finalHeight / 2,
  })

  transformer.setAttrs({
    width: finalWidth,
    height: finalHeight,
    scaleX: 1,
    scaleY: 1,
  })

  border.setAttrs({
    width: finalWidth + 20,
    height: finalHeight + 20,
    offsetX: 0,
    offsetY: 0,
  })

  partition.setAttrs({
    width: finalWidth,
    height: finalHeight,
    offsetX: 0,
    offsetY: 0,
  })

  group.position({
    x: currentX + currentWidth / 2 - appStage.x() / scale,
    y: currentY + currentHeight / 2 - appStage.y() / scale,
  });

  transformer.position(resetedPosition);

  if(rotation !== 0) {
    group.rotation(rotation);
  }

  const normalizedConfigs =  { ...group.attrs.configs, width: finalWidth, height: finalHeight };
  group.setAttrs({ configs: normalizedConfigs });

}
