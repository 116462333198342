import { TextParams, SEAT } from "./Constants";
import {AppStage} from "@/core/AppStage";
import { mainGroups } from "@/core/MainGroups";
import { seatsGroups } from "@/core/SeatsGroup";
import { recoverSectionSeatNumber, fixSectionPosition } from "./section/_utils";
import { recoverTableSeatNumber } from "./table/utils";
import { skewSection, curveSection } from "./section/_generator";
import Konva from "konva";
import { ticketsCount } from "@/core/TicketsCount";

let selectedSeats = [];
let selectedRows = [];
let fixDelay = null;

export const createSeat = (x, y, seatText, invalid, parent, rotation) => {
  const seatSize = SEAT.size;
 
  const showedText = seatText || seatText === 0 ? invalid ? "" : seatText : "+";

  const fontFamily = (seatText || seatText === 0) && invalid ? "icon" : "Lato-Regular";
  const fontSize = (seatText || seatText === 0) && invalid ? 24 : TextParams.SIZE_MD;
    const seat = new Konva.Rect({
      width: seatSize,
      height: seatSize,
      stroke: "white",
      strokeWidth: 1,
      x: x,
      y: y,
      subType: 'seat'
    });

    const seatNumber = new Konva.Text({
      align: "center",
      verticalAlign: "middle",
      x: x + seatSize / 2,
      y: y + seatSize / 2,
      text: showedText,
      fontSize: fontSize,
      fill: "white",
      fontFamily: fontFamily,
      width: seatSize,
      height: seatSize,
      offset: {
        x: seatSize / 2,
        y: seatSize / 2
      },
      rotation: rotation,
      type: 'text',
      subType: 'seat'
    });

    parent.add(seat);
    parent.add(seatNumber);
    return { seat, seatNumber }
}

export const selectSeats = (stage, item, component) => {
  if((item.parent && item.parent.attrs.groupId && item.parent.attrs.groupId === stage.selected[0]) //seat condition
    || (item.attrs && item.attrs.groupId && item.attrs.groupId === stage.selected[0])) {//label condition
    const groupId = stage.selected[0];
    const currentGroup = mainGroups.get(groupId);
    const currentSeats = seatsGroups.get(groupId);
    const { seatsBind } = currentGroup;
    const { configs } =  currentGroup.attrs;
    const isSection = currentGroup.attrs.type === 'section';
    const seats = currentSeats.children;
    if(item.attrs.subType && item.attrs.subType === 'seat' ) {
      selectDirectSeats(stage, item.parent, seatsBind, groupId, configs, component, isSection);
    } else if(item.attrs.type && item.attrs.type === 'label') {
      selectSeatsByLabels(stage, item, seats, groupId, configs, component);
    }

  }
  
}

export const selectSeatsByDrag = (stage, item, component, drag) => {
  if(item.attrs.subType && item.attrs.subType === 'seat' && item.parent.attrs.groupId === stage.selected[0]) {
    drag = true;
    const groupId = stage.selected[0];
    const currentGroup = mainGroups.get(groupId);
    const { configs } =  currentGroup.attrs;
    const seat = item.parent;
    let [fillSeat, textNode] = seat.children;
    const isSection = currentGroup.attrs.type === 'section';
    if((seat.number || seat.number === 0) && !selectedSeats.includes(seat)) {
      
      selectedSeats.push(seat);
      if (!selectedRows.includes(seat.rowId)) {
        selectedRows.push(seat.rowId);
      }
      fillSeat.fill("white");
      textNode.fill("black");
    }
    if(isSection) {
      component.selectSecSeat(groupId, selectedSeats, seat.rowId, configs);
    } else {
        component.selectTableSeat(groupId, selectedSeats, configs);
    }
    
    return drag;
  }
  
}

const selectDirectSeats = (stage, seat, seatsBind, id, configs, component, groupType) => {
  let [fillSeat, textNode] = seat.children;
   
      if (
        (textNode instanceof Konva.Text && textNode.text() === "+") ||
        (textNode instanceof Konva.Image && textNode.attrs.text === "+")
      ) {
        if(groupType) {
          
          recoverSectionSeatNumber(seat, seatsBind, id, configs);
          // if(configs.alignment === "center") {
            selectedSeats = [];
            if(prevEmpty) {
              prevEmpty = null;
            }
            return false
          // }
        } else {
          if(prevEmpty) {
            prevEmpty = null;
          }
          recoverTableSeatNumber(seat, seatsBind, id, configs, component);
          const seatsCount = ticketsCount.getGroupSeats(id) + 1;
          component.countTickets(id, seatsCount, 'seats');
        };
        
      } else {
        if (stage.shiftPressed) {
 
          if (isSelected(seat)) {
            // Deselect seat
            selectedSeats = selectedSeats.filter(selectedSeat => selectedSeat !== seat);
            selectedRows = selectedRows.filter(selectedSeat => selectedSeat !== seat.rowId);
            fillSeat.fill("none");
            textNode.fill("white");
          } else {
            // Select seat

            selectedSeats.push(seat);
            if (!selectedRows.includes(seat.rowId)) {
              selectedRows.push(seat.rowId);
            }
            fillSeat.fill("white");
            textNode.fill("black");
          }
        } else {
          if (selectedSeats.length) {
            for (let i = 0; i < selectedSeats.length; i++) {
              const seat = selectedSeats[i];
              let [prevFillSeat, prevTextNode] = seat.children;
              prevFillSeat.fill("none");
              prevTextNode.fill("white");
            }
          }
          selectedSeats = [seat];
          selectedRows = [seat.rowId];
          fillSeat.fill("white");
          textNode.fill("black");
        }
        if(groupType) {

          component.selectSecSeat(id, selectedSeats, seat.rowId, configs);
        } else {
          component.selectTableSeat(id, selectedSeats, configs);
        }
      }
}

const selectSeatsByLabels = (stage, label, seats, id, configs, component) => {
  const rowId = label.attrs.id;

  if(stage.shiftPressed) {
      seats.forEach(seat => {
      if (seat.rowId === rowId && (seat.number || seat.number === 0)) {
      
        let [fillSeat, textNode] = seat.children;
        if (isSelected(seat)) {
          // Deselect seat
          selectedSeats = selectedSeats.filter(selectedSeat => selectedSeat !== seat);
          selectedRows = selectedRows.filter(selectedSeat => selectedSeat !== rowId);
          fillSeat.fill("none");
          textNode.fill("white");
        } else {
          // Select seat

          selectedSeats.push(seat);
          if (!selectedRows.includes(rowId)) {
            selectedRows.push(rowId);
          }
          fillSeat.fill("white");
          textNode.fill("black");
        }
      }
    });
  } else {
    deselectSeats();
    seats.forEach(seat => {
     
        if (seat.rowId === rowId && (seat.number || seat.number === 0)) {
          
          let [fillSeat, textNode] = seat.children;
          selectedSeats.push(seat)
          fillSeat.fill("white");
          textNode.fill("black");
        }
    });
    selectedRows = [rowId];
  }

  component.selectSecSeat(id, selectedSeats, rowId, configs);

}

export const deselectSeats = () => {
  if (selectedSeats.length) {
    for (let i = 0; i < selectedSeats.length; i++) {
      const seat = selectedSeats[i];
      let [prevFillSeat, prevTextNode] = seat.children;
      prevFillSeat.fill("none");
      prevTextNode.fill("white");
    }
    selectedSeats = [];
    selectedRows = [];
  }
}

export const deselectGroupSeats = (id) => {
  const currentGroup = mainGroups.get(id);
  const currentSeats = seatsGroups.get(id);
  currentGroup.zIndex(0);
  currentSeats.zIndex(0);
  const seatsLength = currentSeats.children.length;
  const seats = currentSeats.children;
  const conf = currentGroup.attrs.configs;
  for (let i = 0; i < seatsLength; i++) {
      seats[i].off('click');
      seats[i].off("mouseover");
  }
  selectedSeats = []; 
  selectedRows = [];

};

export const emptySeat = (seat, seatNumberText, seatWithNumGroup, index, alignment) => {

  seat.setAttrs({
    stroke: null,
    hitstrokeWidth: 0
  });
  seatNumberText.visible(false);

  // seatWithNumGroup.on("mouseenter", () => {
  //   seat.setAttrs({
  //     stroke: "white",
  //   });
  //   seatNumberText.visible(true);
  // });

  // seatWithNumGroup.on("mouseleave", () => {
  //   seat.setAttrs({
  //     stroke: null,
  //   });
  //   seatNumberText.visible(false);
  // });
};

let prevEmpty;
export const hoverSectionItems = (stage, item) => {
  if(item.attrs.subType && item.attrs.subType === 'seat' && item.parent.attrs.groupId === stage.selected[0]) {
    hoverSeat(stage, item)
  } else if(item.attrs.type === 'label' && item.attrs.groupId === stage.selected[0]) {
    stage.container().style.cursor = "pointer";
    if(prevEmpty) {
      leavEmpty(prevEmpty);
    }
  } else {
    stage.container().style.cursor = "default";
    if(prevEmpty) {
      leavEmpty(prevEmpty);
    }
  }
}

const hoverSeat = (stage, seatItem) => {
  const seat = seatItem.parent;
  stage.container().style.cursor = "pointer";
  if(prevEmpty) {
    leavEmpty(prevEmpty);
  }

  if(!seat.number && seat.number !== 0) {
    showEmpty(seat)
  }
}


const showEmpty = (seat) => {
  seat.children[0].setAttrs({ stroke: "white" });
  seat.children[1].visible(true);
  prevEmpty = seat;
}

const leavEmpty = (seat) => {
  seat.children[0].setAttrs({ stroke: '' });
  seat.children[1].visible(false);
  prevEmpty = null;
}

export const checkDeletedSeats = (rows) => {

  const checkInRow = (row) => {
    const notEmpty = row.seats.filter(seat => seat.number);
  
    if(!notEmpty || notEmpty.length === 0) {
   
      if(!row.oldKey) {
        row.oldKey = row.key;
      }

      row.key = '';

    } else if (row.oldKey) {
      
      row.key = row.editedKey ? row.editedKey : row.oldKey; 
      delete row.oldKey
    }

  } 
  if(rows.length > 1) {
    for(let j = 0; j < rows.length; j++) {
      checkInRow(rows[j])
    } 
  } else {
    checkInRow(rows[0])
  }

}

export const deleteSeat = (seatsBindRoot, payload) => {
  const seatsBind = _.cloneDeep(seatsBindRoot);
  const { seat, layerId } = payload.seatData;
  const { newData } = payload;
  const currentGroup = mainGroups.get(layerId);
  const isSection = currentGroup.attrs.type === "section";
  const sameRows = isSection ? theSameRows(seat) : null;
  let findSeat;
  let deletedSeats = [];

  if (seat && seat.length) {
    for (let i = 0; i < seat.length; i++) {
      const seatt = seat[i]; 

      if(isSection) {
        let findRow = seatsBind.find((row) => row.id === seatt.attrs.rowId);
       
        if (sameRows &&  findRow.key !== newData.row) {
          findRow.editedKey = newData.row;
        }
        if(!deletedSeats.includes(findRow)) {
          deletedSeats.push(findRow);
        }
      
        findSeat = findRow.seats.find((seat) => seat.id === seatt.attrs.id);
      } else {
        findSeat = seatsBind.find((seat) => seat.id === seatt.attrs.id);
      }

      findSeat.number = null;
    }

  }

  selectedSeats = [];
  selectedRows = [];

  if(deletedSeats.length > 0) {
    checkDeletedSeats(deletedSeats)
  }

  return seatsBind;
};

export const updateSeat = (seatsBind, payload, type) => {
  const { seat, layerId } = payload.seatData;
  const { newData } = payload;
  
  const currentGroup = mainGroups.get(layerId);
  const isSection = currentGroup.attrs.type === "section";

  if (seat && seat.length) {
    if(type === 'save') {
      fixSeatChanges(layerId, type);
    } else if (type === 'temp') {
      if (seat.length === 1) {
        const textBlock = seat[0].children[1];
        changeSeatText(textBlock, newData.seat, newData.invalid);
      } else if (seat.length > 1) {
        for (let i = 0; i < seat.length; i++) {
          const seatInfo = seat[i];
          const textBlock = seat[i].children[1];
          const invalidInfo = seatInfo.tempInvalid || seatInfo.tempInvalid === false ? seatInfo.tempInvalid : seatInfo.invalid;
          const seatNumber = seatInfo.tempNumber ? seatInfo.tempNumber : seatInfo.number;
          changeSeatText(textBlock, seatNumber, invalidInfo);
        }
      }
      if(isSection) {

        currentGroup.find((node) => {

          if (node.isRow) {
            const rowLabel = node.children.find((r) => r.attrs.id === seat[0].attrs.rowId);
           
              rowLabel.setAttrs({
                text: newData.row,
              });

          } else if(node instanceof Konva.Group && node.rowId === seat[0].attrs.rowId) {
            if(node.row !== newData.row && newData.row === '') {
              node.tempRow = newData.row;
            }
            
          }
        });
      }  
    }

  }

  return seatsBind;
};

export const fixSeatChanges = (id, type) => {
  const currentGroup = mainGroups.get(id);
  const currentSeats = seatsGroups.get(id);
  const seats = currentSeats.children;
  const isSection = currentGroup.attrs.type === 'section';
 
  let seatsBind = currentGroup.attrs.seatsBind;

  seats.forEach((seat, index) => {
    const row = isSection ? seat.attrs.pos[0] : null;
    const indexInRow = isSection ? seat.attrs.pos[1] : null;
    const changedSeatsBind = !isSection ? seatsBind[index] : seatsBind[row].seats[indexInRow];
    if(seat.tempNumber || seat.tempInvalid || seat.tempInvalid === false || seat.tempRow || seat.tempRow === '') {
      const textBlock = seat.children[1];
    
      if (type === 'save') {
        if(seat.tempNumber) {
          seat.editedNumber = seat.tempNumber;
          seat.number = seat.tempNumber;
          
          changedSeatsBind.editedNumber = seat.tempNumber;
          changedSeatsBind.number = seat.tempNumber;
        } 
        if(seat.tempInvalid || seat.tempInvalid === false) {

          seat.invalid = seat.tempInvalid;
          changedSeatsBind.invalid = seat.tempInvalid;
          
        } 
        if(seat.tempRow || seat.tempRow === '') {
          seat.row = seat.tempRow;
          changedSeatsBind.row = seat.tempRow;
          seatsBind[row].key = seat.tempRow;
          seatsBind[row].editedKey = seat.tempRow;
        } 
      }
      let seatNumber = seat.editedNumber ? seat.editedNumber : seat.number;
      if(seat.number || seat.number === 0) changeSeatText(textBlock, seatNumber, seat.invalid);
      if(isSection) {
        currentGroup.find((node) => {
          if (node.isRow) {
           
            const rowLabel = node.children.find((r) => r.attrs.id === seat.rowId);
            
            rowLabel.setAttrs({
              text: seat.row
            });
          } 
        });
      }
     
      if(seat.tempNumber) delete seat.tempNumber;
      if(seat.tempInvalid) delete seat.tempInvalid;
      if(seat.tempRow) delete seat.tempRow;
      
    }
  });
  
  currentGroup.setAttrs({seatsBind});
}

export const shiftRows = (shiftStep, payload) => {
  const { layerId } = payload.seatData;
  const currentGroup = mainGroups.get(layerId);
  const currentSeats = seatsGroups.get(layerId);
  const { configs } = currentGroup.attrs;
  const { curveValue, skewValue, alignment, seats, seatSpacing } = configs;
  const allSeats = currentSeats.children;
  const sectionWidth = (SEAT.size + 5 + 2 * seatSpacing) * seats - 2 * seatSpacing;
  const shiftSize = shiftStep * (SEAT.size + 5 + 2 * seatSpacing)
  if (skewValue) {
     skewSection(0, layerId, configs);
    //  currentGroup.visible(false)
  }

  if (curveValue) {
    curveSection(0, layerId, configs);
    //  currentGroup.visible(false)
  }

  let seatsData = currentGroup.seatsBind;
  
  seatsData.forEach((row, index) => {
   
    if (selectedRows.includes(row.id)) {
      let shift = row.shift ? row.shift : 0;
      let firstSeat = index * parseInt(seats);
      let lastSeat = firstSeat + parseInt(seats);
      const leftEmpty = row.leftEmpty ? row.leftEmpty : 0;
      const rightEmpty = row.rightEmpty ? row.rightEmpty : 0;
      const leftMaxShift = alignment === "center" ? -(leftEmpty + rightEmpty) * (SEAT.size + 5 + 2 * seatSpacing) / 2 : -leftEmpty * (SEAT.size + 5 + 2 * seatSpacing);
      const rightMaxShift = alignment === "center" ? (leftEmpty + rightEmpty) * (SEAT.size + 5 + 2 * seatSpacing) / 2 : rightEmpty * (SEAT.size + 5 + 2 * seatSpacing); 
     
      if(shiftSize < 0 && shift <= leftMaxShift || shiftSize > 0 && shift >= rightMaxShift) {
        return false
      } else {
        shift += shiftSize;
        row.shift = shift;
        for(let i = firstSeat; i < lastSeat; i++) {
          allSeats[i].move({
            x: shiftSize,
          });
       
          if(allSeats[i].attrs.x < -44 || allSeats[i].attrs.x > sectionWidth) {
            allSeats[i].setAttrs({ 
              // offsetX: shift,
              listening: false,
              
            }) 
            allSeats[i].children[0].setAttrs({
              // offsetY: allSeats[i].y() - allSeats[0].y(),
              width: 0,
              height: 0
            })
          } else {
            allSeats[i].setAttrs({
              // offsetX: 0,
              listening: true,
              
            }) 
            allSeats[i].children[0].setAttrs({
              width: SEAT.size,
              height: SEAT.size
            })
     
          }
        }
      } 
    }
  })

  currentGroup.setAttrs({
    seatsBind: seatsData,
  })

  if (skewValue) {
    skewSection(skewValue, layerId, configs);
    setTimeout(()=>{
      currentGroup.visible(true)
    },0)

  }

  if (curveValue) {
    curveSection(curveValue, layerId, configs);
    setTimeout(()=>{
      currentGroup.visible(true)
    },0)
  }

  // const leftLabels = currentGroup.children.find((g) => g.attrs.type === "left");
  //   const rightLabels = currentGroup.children.find((g) => g.attrs.type === "right");

  //  fixSectionPosition(currentGroup, currentSeats, leftLabels, rightLabels);
}

const changeSeatText = (element, text, invalid) => {
  if(element) {
    if(invalid) {
      element.setAttrs({
        text: "",
        fontFamily: "icon",
        fontSize: 24,
      })
    } else {
      element.setAttrs({
        text: text,
        fontFamily: "Lato-Regular",
        fontSize: 14,
      })
    }
  }
}

const isSelected = (seat) => {
  return selectedSeats.includes(seat);
}

const theSameRows = (seats) => {
  let ids = [];

  if (seats && seats.length) {
    ids = seats.map(s => s.attrs.rowId);
  }

  const id_array = [... new Set(ids)]

  return id_array.length === 1;
}

