<template>
    <div class="form_fields">
        <div class="form_btns top_btns">
            <button class="secondary_btn" @click="cancelChanges">Cancel</button>
            <button class="primary_btn" @click="updatePartition">Submit</button>
        </div>

        <div class="field_block">
            <span class="field_name">Rotation <span v-if="editData.rotation !== 0"> ({{ Math.round(editData.rotation * 100) / 100 }})</span></span>
            <range-slider class="slider" min="-90" max="90" step="1" v-model="editData.rotation" @input="changeRotation">
            </range-slider>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import RangeSlider from "vue-range-slider";
    import {
        cancelPartitionChanges,
        rotatePartition,
        updatePartitionStage
    } from "@/helpers/partition/generator";

    import {
        deselectGroup,
unFreezeAllGroups
    } from "@/helpers/utils";

    export default {
        props: {
            editPartition: {
                type: Object,
                default: () => { },
            },
            popup: {
                type: Boolean,
                default: false,
            },
        },
        watch: {
            popup(opened) {
            if (!opened) {
                cancelPartitionChanges(this.editPartition);
            }
            },
        },
        data() {
            return {
                editData: {},
            };
        },
        created() {
            this.editData = _.cloneDeep(this.editPartition);
        },
        methods: {
            editPartitionHelper() {
                const { layerId } = this.editPartition;
                const payload = {
                    partitionData: this.editPartition,
                    newData: this.editData,
                };
                updatePartitionStage(layerId, payload);
            },
            updatePartition() {
                deselectGroup();
                this.$emit("closePopup");
                unFreezeAllGroups();
            },
            cancelChanges() {
                cancelPartitionChanges(this.editPartition)
                this.$emit("closePopup", this.editPartition);
                unFreezeAllGroups();
            },
             changeRotation() {
                
                rotatePartition(this.editPartition.layerId, this.editData.rotation, this.editData);

            },
        },
        components: {
            RangeSlider,
        },
    }
</script>
