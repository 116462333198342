import Konva from "konva";
import { uid } from "uid";

import { deselectGroup, setId } from "../utils";
import { TextParams } from "../Constants";
import {AppStage} from "@/core/AppStage";
import { mainGroups } from "@/core/MainGroups";


let builder;
let borderLayer = {};
let cloneText = {};

//generate text element with default configs
export const generateText = (configs, component, textId, position) => {
    const appStage = new AppStage();
    builder = component;
    const id = textId ? textId : setId();
    const draggable = configs.draggable !== undefined ? configs.draggable : true;

    let mainGroup = new Konva.Group({
        id,
        draggable: draggable,
        type: 'text', 
        category: 'decor',
        configs,
    });  
  
    let text = new Konva.Text({
        type: 'text',
        x: 0,
        y: 0,
        fill: '#ffffff',
        verticalAlign: 'middle',
        fontFamily: !configs.bold ? TextParams.FONT_REGULAR : TextParams.FONT_BOLD,
        text: configs.upperCase ? configs.text.toUpperCase() : configs.text,
        fontSize: configs.fontSize,
        lineHeight: configs.lineHeight,
        letterSpacing: configs.spacing,
        align: configs.alignment
    })

    mainGroup.add(text);

    let textWidth = mainGroup.getClientRect().width;
    let textHeight = mainGroup.getClientRect().height;
    let layerBorder = new Konva.Rect({
        width: textWidth + 20,
        height: textHeight + 20,
        x: -10,
        y: -10,
        dash: [5,5], 
        strokeWidth: 1, 
        type: "border",
    });
  
    mainGroup.add(layerBorder);
    mainGroups.add(mainGroup, id);
    borderLayer[id] = layerBorder;
    borderLayer[id].moveToTop();

    layerBorder.setAttrs({
      width: text.getClientRect().width + 20,
      height: text.getClientRect().height + 20
    })

    if (position) {
 
        mainGroup.setAttrs({
          x: position.x, 
          y: position.y,
          offsetX: textWidth / 2,
          offsetY: textHeight / 2,
        })
          
      } else {
    
        mainGroup.setAttrs({
          offsetX: textWidth / 2,
          offsetY: textHeight / 2,
          x: ((-appStage.x() + appStage.width() / 2) / appStage.scaleX()),
          y: (((-appStage.y() + 40) / appStage.scaleY() + (textHeight / 2))),
        });
        
    }

    const currentGroup = mainGroups.get(id);
    if(configs && configs.rotation) {
        rotateText(currentGroup, configs.rotation, configs)
    }

    if(appStage.editing && currentGroup.attrs.draggable) {
      currentGroup.setAttrs({ 
        draggable: false,
        editDrag: true
      });
    }
  
    return { mainGroup };

}

//change text
export const updateText = (id, params) => {

  const currentGroup = mainGroups.get(id);
  const appStage = new AppStage();
  const text = currentGroup.children.find((g) => g.attrs.type === "text");
  const border = currentGroup.children.find((g) => g.attrs.type === "border");
  if(params.rotation || params.rotation === 0) {
    rotateText(currentGroup, 0, params)
  }

  text.setAttrs({
    fontFamily: !params.bold ? TextParams.FONT_REGULAR : TextParams.FONT_BOLD,
    text: params.upperCase ? params.text.toUpperCase() : params.text,
    fontSize: params.fontSize,
    lineHeight: params.lineHeight,
    letterSpacing: params.spacing,
  })


  const textBlockSize = text.getClientRect();
  currentGroup.setAttrs({
    offsetX: textBlockSize.width / appStage.scaleX() / 2,
    offsetY: textBlockSize.height / appStage.scaleX() / 2,
  })

  border.setAttrs({
    width: textBlockSize.width / appStage.scaleX() + 20,
    height: textBlockSize.height / appStage.scaleX() + 20,
  });

  if(params.rotation || params.rotation === 0) {
    currentGroup.rotation(params.rotation)
  } 
 
  currentGroup.setAttrs({configs: params })
   
};

//text rotate
const rotateText = (group, rotation, configs) => {
  group.setAttrs({ configs: { ...configs, rotation } });
  group.rotation(rotation);
  configs = { ...configs, rotation };
};

//text actions object
export const textActions = {
  editAction: (id, component, configs, stage) => {
    const currentGroup = mainGroups.get(id);
    cloneText[id] = _.cloneDeep(currentGroup);
    AppStage.addKey('editing', id);

    const confs = currentGroup.attrs.configs;
   
    component.editText(id, confs, currentGroup);
  
    borderLayer[id].setAttrs({stroke: ''});
  
  },
  copyAction: (id, component, configs) => {
    const currentGroup = mainGroups.get(id);
    const appStage = new AppStage();
    const mainLayer = appStage.children[0];

    const conf = currentGroup.attrs.configs;
    const updatedConf = {...conf, draggable: true};
    const { mainGroup } = generateText(updatedConf, component);
    mainLayer.add(mainGroup);
    deselectGroup();
    borderLayer[id].setAttrs({stroke: ''});
  }
}

