import Konva from 'konva'
import { buttonsList, buttonSize, buttonsOrder, actionsTypes, transformLimit, anchorsPoint } from './Constants';
import { AppStage } from "@/core/AppStage";
import { mainGroups } from "@/core/MainGroups";
import { textActions } from './text/generator';
import { stageActions } from './stage/generator';
import { areaActions } from './area/generator';
import { tableActions } from './table/generator';
import { sectionActions } from './section/_generator';
import { partitionActions } from './partition/generator';
import { usedIds } from '@/core/UsedIds';
import { ticketsCount } from '@/core/TicketsCount';
import { uid } from 'uid';

export class ActionButtonsGroup {
  constructor(group, component, stage) {
    if (!ActionButtonsGroup.instance) {
      ActionButtonsGroup.instance = this;
    }

    this.actionButtons = new Konva.Group({ type: 'actions' });
    this.buttonSize = 45;
    ActionButtonsGroup.instance.group = group;
    ActionButtonsGroup.instance.component = component;
    ActionButtonsGroup.instance.stage = stage;
    this.buttonActionHandle = this.buttonActionHandle.bind(ActionButtonsGroup.instance);
    return ActionButtonsGroup.instance;
  }

  hideActions() {
    this.actionButtons.visible(false);
  }

  destroyButtons() {
    this.actionButtons.destroy();
  }

  async changeFreezeIcon(freezeType) {

    const freezeIcon = !freezeType ? "/img/freeze_icon.svg" : "/img/unfreeze_icon.svg";
    const loadImg = function (img, url) {
      return new Promise((resolve, reject) => {
        img.src = url;
        img.onload = () => resolve(img);
      });
    };
    const freezeBtn = this.actionButtons.children.find(
      (child) => child.attrs.type === "freezeAction"
    );

    if(freezeBtn) {
      freezeBtn.setAttrs({
        image: await loadImg(new Image(), freezeIcon)
      })
    }
    
  }

  buttonActionHandle(type) { //button type, editAction, copyAction, deleteAction, sortAction
    if(this.group) { //selected element
      if(type === 'editAction' || type === 'copyAction') {
        this.hideActions();
      }

      if(type === 'editAction') {
        freezeAllGroups();
      }

      if(type === 'freezeAction') {
        this.changeFreezeIcon(this.group.attrs.draggable);
        freezeAction(this.group.attrs.id);
      } else if (type === 'deleteAction') {
        this.component.deleteConfirmation(this.group.attrs.id, this.group.attrs.type);
      } else {
        // Todo add cases to constant
        switch (this.group.attrs.type) { //selected element type
          case 'text':
            textActions[type](this.group.attrs.id, this.component, this.group.attrs.configs, this.stage) // text element functions from textActions object
            break;
          case 'stage':
            stageActions[type](this.group.attrs.id, this.component, this.group.attrs.configs, this.stage) //scene element functions from stageActions object
            break;
          case 'round':
          case 'square':
          case 'rectangle':
            tableActions[type](this.group.attrs.id, this.component, this.group.attrs.configs, this.stage)
            break;
          case 'area':
            areaActions[type](this.group.attrs.id, this.component, this.group.attrs.configs, this.stage) //scene element functions from stageActions object
            break;
          case 'section':
            sectionActions[type](this.group.attrs.id, this.component, this.group.attrs.configs, this.stage)
            break;
          case 'partition':
           partitionActions[type](this.group.attrs.id, this.component, this.group.attrs.configs, this.stage) //scene element functions from stageActions object
            break;
        }
      }
    }
  };

  async addActionsGroup(layer) {
    const loadImg = function (img, url) {
      return new Promise((resolve, reject) => {
        img.src = url;
        img.onload = () => resolve(img);
      });
    };

    if(!this.actionButtons.children.length) {
      await Promise.all(
        buttonsList.map(async (item, i) => {
          const { imageUrl, type } = item;
          const actionButtonsElement = new Konva.Image({
            x: i * buttonSize,
            y: 0,
            type,
            image: await loadImg(new Image(), imageUrl),
          });

          actionButtonsElement.addEventListener('click', () => this.buttonActionHandle(type));
          actionButtonsElement.on("mouseenter", function () {
            if(ActionButtonsGroup.instance.stage) {
              ActionButtonsGroup.instance.stage.container().style.cursor = "pointer";
            }
          });

          actionButtonsElement.on("mouseleave", function () {
            if(ActionButtonsGroup.instance.stage) {
              ActionButtonsGroup.instance.stage.container().style.cursor = "default";
            }
          });

          this.actionButtons.add(actionButtonsElement);
        })
      );
    }

    layer.add(this.actionButtons);

    this.actionButtons.width = 0;
    this.actionButtons.position({
      x: 0,
      y: 0,
    });

    this.actionButtons.visible(false);
  }
}

export class StaticClass {
  constructor(group, component, stage) {
    if (!StaticClass.instance) {
      this.buttonGroups = new ActionButtonsGroup();
      StaticClass.instance = this;
    } else {
      StaticClass.instance.group = group;
      StaticClass.instance.component = component;
      StaticClass.instance.stage = stage;
      StaticClass.buttonGroups = new ActionButtonsGroup(group, component, stage);
    }

    // StaticClass.instance.group = group;
    return StaticClass.instance;
  }

  async setButtonsOptions(group) {
    const appStage = new AppStage();
    const actionButtons = this.buttonGroups.actionButtons;

    if(appStage.selected.length > 0) {
      const $border = group.children.find(
        (child) => child.attrs.type === "border"
      );

      const groupType = group.attrs.type;
      const groupX = group.attrs.x;
      const borderY = $border.attrs.y ? $border.attrs.y : 0;
      const groupY = $border.getClientRect().y - appStage.y();
      const groupHeight = $border.getClientRect().height;
      const currentButtons = buttonsOrder[groupType];
      this.buttonGroups.changeFreezeIcon(!group.attrs.draggable);
      actionButtons.width = buttonSize * currentButtons.length;

      for (let i = 0; i < actionButtons.children?.length; i++) {
        const actionsButton = actionButtons.children[i];
        const buttonType = actionsButton.attrs.type;
        const foundType = currentButtons.findIndex((item) => {
          return item === buttonType;
        });

        if(foundType >= 0) {

          actionsButton.visible(true);

          actionsButton.position({
            x: foundType * buttonSize
          })
        } else {
          actionsButton.visible(false);
        }
      }

      actionButtons.visible(true);
      actionButtons.position({
        x: groupX - actionButtons.width / 2 ,
        y: (groupY + groupHeight) / appStage.scaleX() + 20 - borderY
      });
      actionButtons.moveToTop();

      group.on('dragmove', () => {
        if(appStage.selected.length > 0 && group.attrs.id === appStage.selected[0]) {
          const currentHeight = $border.getClientRect().height;
          const bordercurrentY = $border.attrs.y ? $border.attrs.y : 0;
          const newX = group.attrs.x - actionButtons.width / 2;
          const newY = ($border.getClientRect().y  - appStage.y()  + currentHeight )/ appStage.scaleX() + 20 - bordercurrentY;
          actionButtons.position({
            x: newX,
            y: newY
          });
        }
      });

      setTimeout(()=>{
        if(appStage.trElement) {
          appStage.trElement.on('transform', () => {
            const newParams = $border.getClientRect();
            actionButtons.setAttrs({
              x: (newParams.x + newParams.width/ 2 - appStage.x())/appStage.scaleX() - actionButtons.width/2,
              y: (newParams.y - appStage.y() + newParams.height)/appStage.scaleX() + 20
            });
          });
        }
      },0)

    } else {
      actionButtons.visible(false);
      actionButtons.width = 0;
      actionButtons.position({
        x: 0,
        y: 0
      });
    }
  }
}

//count venue parameters after saving
export const countVenueSize = (reposition) => {
  const appStage = new AppStage();
  let maxY = -Infinity;
  let minY = Infinity;

  let minX = Infinity;
  let maxX = -Infinity;

  let oldScale = appStage.scaleX();
  let oldX = appStage.x();
  let oldY = appStage.y();
  AppStage.scale({x: 1, y: 1});
  AppStage.position({x: 0, y: 0});
  AppStage.visible(false);

  if(appStage.transformer) {
    destroyTransformer();
  }

  const allElements = mainGroups.getAll();

  minY = Object.values(allElements).reduce((max, current) => {
      let height  = current.getClientRect().height;
      const { y } = current.attrs;
      return y - height / 2 < max ? y - height / 2 : max;
  }, Infinity);

  maxY = Object.values(allElements).reduce((max, current) => {
    let height = current.getClientRect().height;
    const { y } = current.attrs;
    return y + height / 2 > max ? y + height / 2 : max;
  }, -Infinity);

  minX = Object.values(allElements).reduce((max, current) => {
    let width = current.getClientRect().width;
    const { x } = current.attrs;
    return x - width / 2 < max ? x - width / 2 : max;
  }, Infinity);

  maxX = Object.values(allElements).reduce((max, current) => {
    let width = current.getClientRect().width;
    const { x } = current.attrs;
    return x + width / 2 > max ? x + width / 2 : max;
  }, -Infinity)

  const venueInnerWidth = (maxX - minX) + 80;
  const venueInnerHeight = (maxY - minY) + 80;
  const venueLeft = minX - 40;
  const venueTop = minY - 40;

  AppStage.setAttrs({
    venueWidth: venueInnerWidth,
    venueHeight: venueInnerHeight,
    venueLeft: venueLeft,
    venueTop: venueTop,
  })

  if(reposition) {
    repositionVenue(appStage, allElements, venueInnerWidth, venueInnerHeight, venueLeft, venueTop);
  } else {
    AppStage.scale({x: oldScale, y: oldScale});
    AppStage.position({x: oldX, y: oldY});
  }

  AppStage.visible(true);

}

let hoveredLayer;
let hoveredLayerId;
let groupType;
let selectedGroups;
let dragStart;

const leaveSection = (stage, shape) => {
  if(hoveredLayer && hoveredLayer !== shape) {
    if(stage.selected.length == 0 || stage.selected.length > 0 && !stage.selected.includes(hoveredLayerId)) {
      hoveredLayer.setAttrs({
        stroke: ''
      })
    };
    stage.container().style.cursor = "default";
  } 
}

export const hoverGroup = (stage, shape) => {
  leaveSection(stage, shape)
  if(shape && shape.attrs.type === 'border') {
    hoveredLayer = shape;
    hoveredLayerId = hoveredLayer.parent.attrs.id;
    groupType = hoveredLayer.parent.attrs.type;
    stage.container().style.cursor = "pointer";
    shape.setAttrs({stroke: 'gray'})
  }
}

export const hoverActions = (stage, shape) => {
  if(shape.attrs && actionsTypes.includes(shape.attrs.type)) {
    stage.container().style.cursor = "pointer";
  }
}

export const selectGroup = async (stage, shape, component) => {
  if(shape && shape.attrs.type === 'border') {
    const currentGroup = shape.parent;
    const currentId = shape.parent.attrs.id;
    
    if(stage.shiftPressed) {
      selectMultiGroups(stage, shape, currentGroup, currentId);
    } else {
      const initactions = new StaticClass(currentGroup, component, stage);
      
      if(stage.selected.length > 1) {
        deselectAllGroups(stage, currentId);
      };

      if(stage.selected.length > 0) {
        if(stage.selected[0] === currentId) {
          deselectCurrenteGroup(stage, currentGroup);
        } else {
          deselectPrevGroup(stage);
          selectSingleGroup(currentGroup, currentId);
        }
      } else {
        selectSingleGroup(currentGroup, currentId);
      }
      
      await initactions.setButtonsOptions(currentGroup);
    }
  } else if (shape === stage) {
    deselectAllGroups(stage)
  }
}

const selectMultiGroups = (stage, shape, group, id) => {
  const actionsButtons = new ActionButtonsGroup();
  group.moveToTop();
  if(stage.transformer) {
    destroyTransformer();
  }

  actionsButtons.hideActions();

  shape.setAttrs({stroke: 'gray'});

  AppStage.addArr(id);
}

const selectSingleGroup = (group, id) => {
  AppStage.addArr(id);
  group.moveToTop();
  if(group.attrs.transforming) {
    setTimeout(() => {
      activateTransformer(group);
    }, 0);
  };
}

const deselectCurrenteGroup = (stage, group) => {
  AppStage.resetSelected();
  group.moveToBottom();
  if(stage.transformer) {
    destroyTransformer();
  }
}

const deselectPrevGroup = (stage) => {
  const oldSelected = stage.selected[0];
  const allItems = mainGroups.getAll();

  for (const [key, value] of Object.entries(allItems)) {
    if(key === oldSelected) {
      const layerBorder = value.children.find(
        (child) => child.attrs.type === "border"
      );

      layerBorder.setAttrs({stroke: ''});

      if(stage.transformer) {
        destroyTransformer();
      }
    }
  }

  AppStage.resetSelected();
}

//select/unselect group
export const selectGroup2 = ($element, id, component) => {
  const appStage = new AppStage();

  $element.on("click", async () => {
    if(appStage.shiftPressed && !appStage.editing) {

      
      const layerBorder = $element.children.find(
        (child) => child.attrs.type === "border"
      );

      if(appStage.transformer) {
        destroyTransformer();
      }

      actionsButtons.hideActions();
      layerBorder.visible(true);
      
      AppStage.addArr(id);

    } else if(!appStage.editing) {
        const currentGroup = $element;
        const initactions = new StaticClass(currentGroup, component, appStage);
        const transforming = currentGroup.attrs.transforming;

        if(appStage.selected.length > 1) {
          deselectAllGroups(appStage, id);
          
        }

        if(appStage.selected.length > 0) {
          if(appStage.selected[0] === id) {
              AppStage.resetSelected()
            if(appStage.transformer) {
              destroyTransformer();
            }
     
          } else {
            const oldSelected = appStage.selected[0];
            const allItems = mainGroups.getAll();
    
            for (const [key, value] of Object.entries(allItems)) {
              if(key === oldSelected) {
                const layerBorder = value.children.find(
                  (child) => child.attrs.type === "border"
                );

                layerBorder.setAttrs({stroke: ''});

                if(appStage.transformer) {
                  destroyTransformer();
                }
              }
            }

            AppStage.resetSelected()
            AppStage.addArr(id);

            if(transforming) {
              setTimeout(() => {
                activateTransformer($element);
              }, 0);
            };

          }
        } else {
          AppStage.addArr(id);
          if(transforming) {
            setTimeout(() => {
              activateTransformer($element);
            }, 0);
          };
        
        }

        await initactions.setButtonsOptions(currentGroup);
      }
  })
}
 
export const deselectGroup = () => {
  const appStage = new AppStage();
  AppStage.addKey('editing', null);
  AppStage.resetSelected();

  if (appStage.transformer) {
    destroyTransformer();
  }
};

const activateTransformer = (group) => {
  const appStage = new AppStage();
  const transformPoints = anchorsPoint[group.attrs.type];

  let transformer = group.children.find(//find transforming element in current group
    (child) => child.attrs.type === 'transform'
  );

  let tr = new Konva.Transformer({
    keepRatio: false, // Disable aspect ratio preservation
    nodes: [transformer],
    enabledAnchors: transformPoints,
    borderStroke: "gray",
    anchorFill: "none",
    anchorSize: 8,
    rotateEnabled: false,
  });

  group.add(tr);
  AppStage.addKey('transformer', tr)
  AppStage.addKey('trElement', transformer);

  appStage.trElement.on('transformstart', ()=>{
    limitTrBoundBox(appStage.transformer, group, appStage.scaleX());
  })
}

export const destroyTransformer = () => {
  const appStage = new AppStage();
  appStage.transformer.destroy();
  AppStage.addKey('transformer', null)
  AppStage.addKey('trElement', null);
}

const limitTrBoundBox = (transformer, group, scale) => {

  let groupLimitedSizes = transformLimit[group.attrs.type];
  transformer.boundBoxFunc(function (oldBox, newBox) {
    if (newBox.width < groupLimitedSizes[0]*scale || newBox.width > groupLimitedSizes[2]*scale) {
      return oldBox;
    }

    if (newBox.height < groupLimitedSizes[1]*scale || newBox.height > groupLimitedSizes[3]*scale ) {
      return oldBox;
    }

    return newBox;
  });
}

export const resetParams = (id, configs) => {
  const currentGroup = mainGroups.get(id);
  const confs = currentGroup.attrs.configs;
  const updateConfigs = { ...confs, skew: 0, rotation: 0, curveValue: 0 };

  currentGroup.setAttrs({ configs: updateConfigs });
  configs = updateConfigs;
}

const freezeAction = (id) => {
  const currentGroup = mainGroups.get(id);
    const conf = currentGroup.attrs.configs;

    if(conf.draggable === undefined || conf.draggable) {
      currentGroup.setAttrs({ configs: { ...conf, draggable: false } });
      currentGroup.setAttrs({ draggable: false })
    } else {
      currentGroup.setAttrs({ configs: { ...conf, draggable: true } });
      currentGroup.setAttrs({ draggable: true });
    }
}

function handleShiftKeyPress(event) {
  if(AppStage.instance && event.shiftKey) {
    AppStage.addKey('shiftPressed', true);
  }
}

// Глобальная функция для обработки событий keyup
function handleShiftKeyRelease(event) {
  if(AppStage.instance) {
    AppStage.addKey('shiftPressed', false);
  }
}

// Добавление обработчиков событий
export function addListeningToShiftKeys() {
  document.addEventListener('keydown', handleShiftKeyPress);
  document.addEventListener('keyup', handleShiftKeyRelease);
}

// Глобальная функция для остановки обработчиков событий
export function stopListeningToShiftKeys() {
  document.removeEventListener('keydown', handleShiftKeyPress);
  document.removeEventListener('keyup', handleShiftKeyRelease);
}

export const startMultiDrag = (stage, item) => {
  const allItems = mainGroups.getAll();
  const selectedIds = stage.selected;
  selectedGroups = selectedIds ? Object.values(allItems).filter(el => selectedIds.includes(el.attrs.id)) : null;
  dragStart = [item.x(), item.y()]; 
  return { selectedGroups, dragStart }
}

export const dragMultiGroups = (stage, item) => {
    const selectedIds = stage.selected;
    let dragSize = [item.x(), item.y()];

    let dx = dragSize[0] - dragStart[0];
    let dy = dragSize[1] - dragStart[1];

    if(selectedIds && selectedIds.includes(item.attrs.id)) {
      if(selectedGroups) {
        selectedGroups.forEach(group => {
          const frozen = group.attrs.configs.draggable !== undefined ? !group.attrs.configs.draggable : false;
          if(group.attrs.id !== item.attrs.id && !frozen) {
            group.move({
              x: dx,
              y: dy
            })
          }
        });
      }
    }


    dragStart = dragSize;
}

export const dragGroups = ($element, stage) => {

  
  let selectedIds, selectedGroups, dragStart;

  $element.on('dragstart', (item, stage) => {
    let selectedIds = stage.selected;
    selectedGroups = selectedIds ? Object.values(allItems).filter(el => selectedIds.includes(el.attrs.id)) : null;
    dragStart = [$element.x(), $element.y()];
  })

  $element.on('dragmove', () => {
    let dragSize = [$element.x(), $element.y()];

    let dx = dragSize[0] - dragStart[0];
    let dy = dragSize[1] - dragStart[1];

    if(selectedIds && selectedIds.includes($element.attrs.id)) {
      if(selectedGroups) {
        selectedGroups.forEach(group => {
          const frozen = group.attrs.configs.draggable !== undefined ? !group.attrs.configs.draggable : false;
          if(group.attrs.id !== $element.attrs.id && !frozen) {
            group.move({
              x: dx,
              y: dy
            })
          }
        });
      }
    }


    dragStart = dragSize;
  })
} 

const deselectAllGroups = (stage, reselect) => {
  if(stage.selected) {
    const selectedIds = stage.selected;
    AppStage.resetSelected()
    const allItems = mainGroups.getAll();
    const selectedGroups = Object.values(allItems).filter(el => selectedIds.includes(el.attrs.id));
  
    if(!reselect) {
      const actionsButtons = new ActionButtonsGroup();
      actionsButtons.hideActions();

      if(stage.transformer) {
        destroyTransformer();
      }
    }

    selectedGroups.forEach(group => {
      if(!reselect || reselect && group.attrs.id !== reselect) {
        group.moveToBottom();
        const $border = group.children.find(
          (child) => child.attrs.type === "border"
        );

        $border.setAttrs({stroke:''});
      }
    })
  }
}

export const removeUnnecessaryElements = () => {
  const actionsButtons = new ActionButtonsGroup();
  const allElements = mainGroups.getAll();
  actionsButtons.destroyButtons();
  Object.values(allElements).forEach(group => {
    group.children.forEach(element => {
      if (element.attrs.type === "border" || element.attrs.type === "transform") {
        element.destroy();
      }
    });
  })
}

export const getStageJson = (id) => {
  const appStage = new AppStage();

  if (Object.keys(mainGroups).length) {
    countVenueSize();
  }

  let stageJSON = appStage.toJSON();
  let image = appStage.toDataURL();
  return { stageJson: stageJSON, image };
};

export const exportVenueJson = (json, name) => {
  const currentDate = new Date();
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Месяцы в JavaScript начинаются с 0
  const year = currentDate.getFullYear();

  var blob = new Blob([json], { type: 'application/json' });
  var url = URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.href = url;
  a.download = name + `_${year}_${month}_${day}_${hours}_${minutes}` + '.json';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export const repositionVenue = (stage, allElements, venueWidth, venueHeight, venueLeft, venueTop) => {

  for (const [key, value] of Object.entries(allElements)) {
    value.move({
      x: -venueLeft,
      y: -venueTop
    });

    if(stage.selected && stage.selected.length === 1 && key === stage.selected[0]) {
      const actionsButtons = new ActionButtonsGroup();
      const layerBorder = value.children.find(
        (child) => child.attrs.type === "border"
      );

      layerBorder.setAttrs({stroke: ''});

      actionsButtons.hideActions();
    }
  }

  const xScale = window.innerWidth / venueWidth;
  const yScale = window.innerHeight / venueHeight;

  const scaleSize =
    xScale < 1 || yScale < 1 ? (xScale > yScale ? yScale : xScale) : 1;

  const xShift =
     (window.innerWidth - venueWidth * scaleSize )  / 2 ;
  const yShift =
     (window.innerHeight - venueHeight * scaleSize)   / 2 ;

  stage.position({
    x: xShift,
    y: yShift
  })

  stage.scale({ x: scaleSize, y: scaleSize });
  if(stage.attrs.hold && scaleSize > stage.attrs.hold || scaleSize > 0.2) {
    document.querySelector('.min_btn').classList.remove('inactive');
  }
  document.querySelector('.max_btn').classList.remove('inactive');
}

export const handleKeyActions = (e, component) => {
  const appStage = new AppStage();
  let shiftSize = appStage.shiftPressed ? 5 : 1;
  switch (e.key) {
    case 'ArrowUp':
      shiftGroup('y', -shiftSize)
      break;
    case 'ArrowDown':
      shiftGroup('y', shiftSize)
      break;
    case 'ArrowLeft':
      shiftGroup('x', -shiftSize)
      break;
    case 'ArrowRight':
      shiftGroup('x', shiftSize)
      break;
    case 'Delete':
    case 'Backspace':
      if(appStage.selected.length > 0 && !appStage.editing) {
        deleteGroupByKeyboard(component)
      }
      break;
    case 'Enter': 
      if(component.deleteElementPopup) {
        component.deleteElement();
      }
      break;
    case 'Escape': 
      if(component.deleteElementPopup) {
        component.deleteElementPopup = null;
      }
      break;
  }
};

const shiftGroup = (axis, moveSize) => {
  const appStage = new AppStage();
  const allItems = mainGroups.getAll();
  let selectedGroups;
  const selectedIds = appStage.selected;
  if(!appStage.editing) {
    if(selectedIds.length > 1) {
      selectedGroups = selectedIds ? Object.values(allItems).filter(el => selectedIds.includes(el.attrs.id)) : null;
      if(selectedGroups) {
        selectedGroups.forEach(group => {
          const frozen = group.attrs.configs.draggable !== undefined ? !group.attrs.configs.draggable : false;
          if(!frozen) {
            let moveObj = {};
            moveObj[axis] = moveSize;
            group.move(moveObj);
          }
        });
      }
    } else if (selectedIds.length === 1) {
      const currentElement = mainGroups.get(selectedIds[0]);
      const mainLayer = appStage.children[0];
      const actionsButtons = mainLayer.children.find(
        (child) => child.attrs.type === "actions"
      );
      let moveObj = {};
      moveObj[axis] = moveSize;
      currentElement.move(moveObj);
      actionsButtons.move(moveObj)
    }
  }
}

const deleteGroupByKeyboard = (component) => {
    const appStage = new AppStage();
    if(appStage.selected.length === 1) {
      const groupId = appStage.selected[0];
      const groupType = mainGroups.get(groupId).attrs.type;
      component.deleteConfirmation(groupId, groupType);
    } else {
      let ids = [];
      let types = []
      for(let i = 0; i < appStage.selected.length; i++) {
        const groupId = appStage.selected[i];
        const groupType = mainGroups.get(groupId).attrs.type;
        ids.push(groupId);
        types.push(groupType);
      }
      component.deleteConfirmation(ids, types);
    }
}

export const deleteElement = (id) => {
  const currentGroup = mainGroups.get(id);
  currentGroup.destroy();
  mainGroups.remove(id);
  AppStage.resetSelected();
};

export function throttle(func, limit) {
  let inThrottle;
  return function() {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  }
}

const freezeAllGroups = () => {
  const allElements = mainGroups.getAll();
  Object.values(allElements).forEach(group => {
    if(group.attrs.draggable) {
      group.setAttrs({
        draggable: false,
        editDrag: true
      })
    }
  })
}

export const unFreezeAllGroups = () => {
  const allElements = mainGroups.getAll();
  Object.values(allElements).forEach(group => {
    if(group.attrs.editDrag) {
      group.setAttrs({
        draggable: true,
      });
      delete group.attrs.editDrag
    }
  })
}

export const setId = () => {
  const newId = uid(6);

  if(usedIds[newId]) {
   
    setId()
  } else {
    usedIds.add(newId);
    return newId
  }
}

export const countTicketsAfterRemove = (id) => {
  ticketsCount.removeSeat(id);
  ticketsCount.removeFloor(id);
}

export const countAllTickets = () => {
  const seats = ticketsCount.getTotalSeats();
  const floors = ticketsCount.getTotalFloors();
  return { seats, floors }
}