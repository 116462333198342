import Konva from "konva";

export class UsedIds {
    map = {};

    add(id) {
        this.map[id] = id;
    }

    clear() {
        this.map = {};
    }

    getLength() {
        return Object.keys(this.map).length;
    }

    has(id) {
        return this.map.hasOwnProperty(id);
    }
}

export const usedIds = new UsedIds();