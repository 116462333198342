import Konva from "konva";

export class TicketsCount {
    constructor() {
      this.seats = {};
      this.floors = {};
    }
  
    addOrUpdateSeat(groupId, count) {
      this.seats[groupId] = count;
    }

    addOneMoreSeat(groupId) {
      this.seats[groupId] = this.seats[groupId] + 1
    }
  
    addOrUpdateFloor(groupId, count) {
      this.floors[groupId] = count;
    }
  
    removeSeat(groupId) {
      delete this.seats[groupId];
    }
  
    removeFloor(groupId) {
      delete this.floors[groupId];
    }

    getGroupSeats(groupId) {
      return this.seats[groupId];
    }

    clearSeatsCount() {
      this.seats = {};
    }

    clearFloorsCount() {
      this.floors = {};
    }
  
    getTotalSeats() {
      return Object.values(this.seats).reduce((total, count) => total + count, 0);
    }
  
    getTotalFloors() {
      return Object.values(this.floors).reduce((total, count) => total + count, 0);
    }
  }
  
  export const ticketsCount = new TicketsCount();
  